import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { useIntl, defineMessages } from "react-intl";

import InputStreamUdpSection from "../../EngineDirect/InputStreamSection/InputStreamUdpSection";
import InputStreamSrtSection from "../../EngineDirect/InputStreamSection/InputStreamSrtSection";

import InputNumberField from "../../../Fields/InputNumberField";
import InputTextField from "../../../Fields/InputTextField";
import InputStreamVideoStreamSelector from "./InputStreamVideoSelector";
import InputStreamAudioSelector from "./InputStreamAudioSelector";
import AdvancedSettings from "../../../AdvancedSettings";
import StreamIdFields from "../../../StreamIdFields";
import InputStreamSectionBackupTriggers from "../../InputStreamSectionBackupTriggers";

import { CONNECTION_METHOD_INPUT, QUICKSTREAM_METHOD_V2 } from "../../../../lib/utils/constants";
import InputTranscodingConnectionMethod from "../../../InputTranscodingConnectionMethod";
import PassphraseField from "../../../Fields/PassphraseField";
import CloudChannelListV5 from "../../../Fields/CloudChannelListV5";
import InputStreamQSSection from "../../../InputStreamQSSection";

import {
  MAIN_CONNECTION_TYPE,
  INPUT_BACKUP_TRIGGERS_TYPE,
  INPUT_BACKUP_TYPE,
} from "../../../../lib/utils/types/inputURLTypes";
import { required } from "../../../../lib/utils/formValidation";

import OverheadBandwidthLabel from "../../Labels/OverheadBandwidthLabel";
import { PortField } from "../../../Fields/Fields";
import LocalPortLabel from "../../../LocalPortLabel";
import { FormV5Context } from "../../NodeChannelFormV5";

const SRT_METHODS = [CONNECTION_METHOD_INPUT.inSrtListener.value, CONNECTION_METHOD_INPUT.inSrtCaller.value];
const UDP_METHODS = [CONNECTION_METHOD_INPUT.inUdpUnicast.value, CONNECTION_METHOD_INPUT.inUdpMulticast.value];

const translations = defineMessages({
  cloudIdLabel: {
    id: "InputTranscodingStreamURLForm.cloudIdLabel",
    defaultMessage: "Cloud ID",
  },
});

function InputTranscodingStreamURLForm({ disabled, initialValue, sharedChannelList }) {
  const form = useContext(FormV5Context);
  const { getFieldValue, setFieldsValue } = form;
  const { formatMessage } = useIntl();

  const [connectionMethod, setConnectionMethod] = useState(
    initialValue?.inputStreamCfg?.type || CONNECTION_METHOD_INPUT.inUdpMulticast.value
  );

  const isUDPMethod = UDP_METHODS.includes(connectionMethod);
  const isSRTMethod = SRT_METHODS.includes(connectionMethod);
  const isCallerMode = connectionMethod === CONNECTION_METHOD_INPUT.inSrtCaller.value;
  const isListenerMode = connectionMethod === CONNECTION_METHOD_INPUT.inSrtListener.value;
  const isQuickstreamMethod = connectionMethod === QUICKSTREAM_METHOD_V2.inQSDirect.value;

  return (
    <>
      <Row type="flex" justify="start" gutter={24}>
        <InputNumberField name={["mainInput", "inputStreamCfg", "inputId"]} disabled={disabled} hidden />
        <InputTextField
          name={["mainInput", "inputStreamCfg", "inputName"]}
          disabled={disabled}
          required={false}
          hidden
        />
        <Col span={24}>
          <InputTranscodingConnectionMethod
            name={["mainInput", "inputStreamCfg", "type"]}
            disabled={disabled}
            setConnectionMethod={setConnectionMethod}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
          />
        </Col>
        {isQuickstreamMethod && (
          <>
            <Col span={12}>
              <CloudChannelListV5
                channelList={sharedChannelList}
                disabled={disabled}
                getFieldValue={getFieldValue}
                initialValue={
                  initialValue?.mainConnection?.cloudId && initialValue?.mainConnection?.cloudName
                    ? {
                        cloudId: initialValue?.mainConnection?.cloudId,
                        channelName: initialValue?.mainConnection?.cloudName,
                        permissionId: initialValue?.mainConnection?.permissionId,
                        description: initialValue?.mainConnection?.description,
                      }
                    : null
                }
                name={["mainInput", "inputStreamCfg"]}
                label={formatMessage(translations.cloudIdLabel)}
                sameAsValidation="output"
                setFieldsValue={setFieldsValue}
                isInput
              />
            </Col>
            <Col span={12}>
              <PortField
                name={["mainInput", "inputStreamCfg", "localPort"]}
                disabled={disabled}
                label={<LocalPortLabel />}
                min={1}
              />
            </Col>
            <InputTextField name={["mainInput", "inputStreamCfg", "cloudName"]} disabled={disabled} hidden />
            <InputTextField name={["mainInput", "inputStreamCfg", "permissionId"]} disabled={disabled} hidden />
            <InputTextField name={["mainInput", "inputStreamCfg", "cloudId"]} disabled={disabled} hidden />
            <InputTextField name={["mainInput", "inputStreamCfg", "description"]} disabled={disabled} hidden />
          </>
        )}
        {isUDPMethod && (
          <InputStreamUdpSection
            connectionMethod={connectionMethod}
            disabled={disabled}
            prefix={["mainInput", "inputStreamCfg"]}
          />
        )}
        {isSRTMethod && (
          <InputStreamSrtSection
            connectionMethod={connectionMethod}
            disabled={disabled}
            getFieldValue={getFieldValue}
            initialValue={initialValue}
            prefix={["mainInput", "inputStreamCfg"]}
            setFieldsValue={setFieldsValue}
            form={form}
          />
        )}
      </Row>

      <AdvancedSettings>
        {isSRTMethod && (
          <Row gutter={24}>
            {isCallerMode && (
              <Col md={24} lg={12}>
                <PortField
                  name={["mainInput", "inputStreamCfg", "localPort"]}
                  disabled={disabled}
                  label={<LocalPortLabel noTooltip={isListenerMode} />}
                  min={0}
                />
              </Col>
            )}
            <Col md={24} lg={12}>
              <InputNumberField
                disabled={disabled}
                name={["mainInput", "inputStreamCfg", "srtOverhead"]}
                label={OverheadBandwidthLabel()}
                rules={[required]}
                min={5}
                max={100}
              />
            </Col>
            <StreamIdFields prefix={["mainInput", "inputStreamCfg"]} form={form} disabled={disabled} />
            <Col md={24} lg={24}>
              <PassphraseField
                disabled={disabled}
                getFieldValue={getFieldValue}
                prefix={["mainInput", "inputStreamCfg"]}
                setFieldsValue={setFieldsValue}
                type="mainInput"
              />
            </Col>
          </Row>
        )}
        <InputStreamVideoStreamSelector prefix={["mainInput", "videoStreamSelector"]} disabled={disabled} />
        <InputStreamAudioSelector disabled={disabled} prefix={["mainInput", "audioStreamSelectors"]} />
        {isQuickstreamMethod && (
          <InputStreamQSSection
            disabled={disabled}
            getFieldValue={getFieldValue}
            isInput
            name={["mainInput", "inputStreamCfg"]}
            prefix={["mainInput", "inputStreamCfg"]}
            setFieldsValue={setFieldsValue}
          />
        )}
        <InputStreamSectionBackupTriggers disabled={disabled} prefix={["mainInput", "backupTriggers"]} form={form} />
      </AdvancedSettings>
    </>
  );
}

InputTranscodingStreamURLForm.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.shape({
    ...MAIN_CONNECTION_TYPE,
    ...INPUT_BACKUP_TYPE,
    ...INPUT_BACKUP_TRIGGERS_TYPE,
  }),
};

InputTranscodingStreamURLForm.defaultProps = {
  disabled: null,
  initialValue: null,
};

export default InputTranscodingStreamURLForm;
