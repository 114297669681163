import React from "react";
import { Radio, Tooltip, Badge, Popconfirm } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { selectors as MQTT_SELECTORS } from "../../ducks/mqtt";
import MQTTService from "../../services/mqtt";
import themeColor from "../../lib/style/theme";

import {
  MESSAGE_TYPE,
  NODE_CHANNEL_STATE,
  INPUTS_STATUS_IDS,
  INPUT_CHANNEL_STATE,
  ENGINE_TYPES,
  INPUT_STREAM_TYPE,
} from "../../lib/utils/constants";

const { Group, Button } = Radio;

const setSourceLabel = (sourceId) => {
  switch (sourceId) {
    case INPUTS_STATUS_IDS.MAIN:
      return {
        label: "MAIN",
        value: "main",
      };
    case INPUTS_STATUS_IDS.BACKUP:
      return {
        label: "BACKUP",
        value: "backup",
      };
    case INPUTS_STATUS_IDS.EMERGENCY:
      return {
        label: "EMERG.",
        value: "emergency",
      };
    default:
      return {
        label: "Not supported",
        value: "Not supported",
      };
  }
};

const setSourceColor = (threadStatusText) => {
  switch (threadStatusText) {
    case INPUT_CHANNEL_STATE.CONNECTING:
    case INPUT_CHANNEL_STATE.RECONNECTING:
    case INPUT_CHANNEL_STATE.CLOSING:
      return { color: "volcano", status: "precessing" };
    case INPUT_CHANNEL_STATE.CONNECTED:
      return { color: themeColor.green, status: "precessing" };
    case INPUT_CHANNEL_STATE.CLOSED:
      return { status: "default" };
    case INPUT_CHANNEL_STATE.ERROR:
      return { color: themeColor.darkRed, status: "error" };
    default:
      return { status: "default" };
  }
};

export default function SourceSwitch({ playingSource, requestedStatusText, channelId, inputsStatus, type }) {
  const isValidBackupStatus = [NODE_CHANNEL_STATE.STARTED].includes(requestedStatusText);
  const isEngineTranscoding = type === ENGINE_TYPES.TRANSCODING;
  const nodeData = useSelector(MQTT_SELECTORS.getActiveNode);

  const onChangeSource = (selectedSource) => {
    MQTTService.sendMessageV5({
      topic: `node/${nodeData.cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.STREAM_SET_SOURCE,
        channelId,
        useSource: selectedSource,
      },
    });
  };

  const DEFAULT_INPUT_EMERGENCY = {
    id: -1,
  };

  const parsedInputsStatus = isEngineTranscoding ? [...inputsStatus, DEFAULT_INPUT_EMERGENCY] : inputsStatus;

  return (
    <Tooltip
      placement="top"
      title={
        !isValidBackupStatus ? (
          <FormattedMessage id="SourceSwitch.channelMustBeConnected" defaultMessage="Channel must be connected" />
        ) : null
      }
    >
      <StyledSourceSwitch value={playingSource} buttonStyle="solid" disabled={!isValidBackupStatus} size="small">
        {parsedInputsStatus.map((input) => {
          const inputStateColor = setSourceColor(input?.threadStatusText);
          const sourceData = setSourceLabel(input.id);
          const isActiveSource = playingSource === sourceData.value;
          const isEmergency = input.id === INPUTS_STATUS_IDS.EMERGENCY;

          return (
            <Popconfirm
              disabled={isActiveSource}
              placement="right"
              title={
                <>
                  <FormattedMessage
                    id="SourceSwitch.confirm"
                    defaultMessage="Are you sure, you want to change input source to:"
                  />{" "}
                  {sourceData.label}
                </>
              }
              onConfirm={() => onChangeSource(sourceData.value)}
              okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
              cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
              key={input.id}
            >
              <Button
                value={sourceData.value}
                disabled={
                  (isEmergency && playingSource !== INPUT_STREAM_TYPE.emergency) || (isEmergency && !playingSource)
                }
              >
                <Tooltip placement="top" title={input?.threadStatusText} mouseEnterDelay={0.8}>
                  {isEmergency && sourceData.label}
                  {!isEmergency && <Badge text={sourceData.label} {...inputStateColor} />}
                </Tooltip>
              </Button>
            </Popconfirm>
          );
        })}
      </StyledSourceSwitch>
    </Tooltip>
  );
}

const StyledSourceSwitch = styled(Group)`
  margin-left: 20px;
`;

SourceSwitch.propTypes = {
  type: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  requestedStatusText: PropTypes.string.isRequired,
  playingSource: PropTypes.string.isRequired,
  inputsStatus: PropTypes.arrayOf(
    PropTypes.shape({
      connectionInfo: PropTypes.string.isRequired,
      errorCode: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      permissionId: PropTypes.number.isRequired,
      threadStatus: PropTypes.number.isRequired,
      threadStatusText: PropTypes.string.isRequired,
    })
  ).isRequired,
};
