import React, { useState } from "react";
import { Card, Row, Button, Modal, Col, Empty, Spin, Popconfirm } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { connect } from "react-redux";
import { PlusOutlined, EditOutlined, SearchOutlined, QuestionCircleOutlined, SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import { selectors as NODE_DEVICES_SELECTORS } from "../../../ducks/nodeDevices";

import parseConnectionMethodName from "../../../lib/utils/parseConnectionMethodName";
import inputURLDefaultValues from "../../../lib/utils/defaultValues/inputURLDefaultValues";

import {
  QUICKSTREAM_METHOD_V2,
  INPUT_DECKLINK_TYPE,
  INPUT_URL_TYPE,
  SELECT_DEFAULT_AUTO,
  INPUT_WEBCAM_TYPE,
  INPUT_TYPES,
} from "../../../lib/utils/constants";

import InputStreamSection from "../InputStreamSection";
import ProbeDrawer from "./ProbeDrawer";
import InputStreamSourceElement from "../../InputStreamSourceElement";
import DecklinkElement from "../../DecklinkElement";
import WebcamElement from "../../WebcamElement";
import BackupSwitch from "./BackupSwitch";
import InputNDIElement from "../../InputNDIElement";

const translations = defineMessages({
  main: {
    id: "InputSection.main",
    defaultMessage: "Main",
  },
  backup: {
    id: "InputSection.backup",
    defaultMessage: "Backup",
  },
});

const InputSection = ({
  channelConfig,
  decklinkInputs,
  detailView,
  getFieldValue,
  handleFinish,
  id,
  requestedStatusText,
  resetFields,
  setFieldsValue,
  sharedChannelList,
  stundAddress,
  usesBackup,
  validateFields,
  form,
}) => {
  const { formatMessage } = useIntl();
  const [inputStreamModalVisible, setInputStreamModalVisible] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedInputType, setSelectedInputType] = useState(channelConfig?.input?.type);

  const isInitialValueInputUrlType =
    channelConfig?.input?.type === INPUT_URL_TYPE.value && selectedInputType !== INPUT_URL_TYPE.value;

  const handleOKMainInput = async () => {
    try {
      await validateFields();
      const formInput = getFieldValue("input");
      const formNonMuxedOutputs = getFieldValue("nonMuxedOutputs");
      const outputsToRemove = isInitialValueInputUrlType && formNonMuxedOutputs && formNonMuxedOutputs.length > 0;

      if (formInput.type !== channelConfig?.input?.type && outputsToRemove) {
        const updatedFormData = { ...channelConfig, input: formInput, nonMuxedOutputs: null };

        setFieldsValue({ nonMuxedOutputs: null });

        handleFinish(updatedFormData);
        setInputStreamModalVisible(false);

        return;
      }

      const updatedFormData = { ...channelConfig, input: formInput };

      handleFinish(updatedFormData);
      setInputStreamModalVisible(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Validation Error", error);
    }
  };

  const handleCancelMainInput = () => {
    resetFields();
    setInputStreamModalVisible(false);
  };

  const handleShowEditModal = () => {
    if (!channelConfig?.input) {
      setFieldsValue({
        ...channelConfig,
        input: inputURLDefaultValues({ input: channelConfig?.input, stundAddress, sharedChannelList }),
      });
    }

    setInputStreamModalVisible(true);
  };

  const handleShowProbe = async () => {
    setShowDrawer(true);
  };

  const isInput = channelConfig?.input;
  const isBackup = !!channelConfig?.input?.switchInputBackup;
  const isMainQSMethod = !!(channelConfig?.input?.mainConnection?.type === QUICKSTREAM_METHOD_V2.inQSDirect.value);

  const isMainInputDecklinkType = channelConfig?.input?.type === INPUT_DECKLINK_TYPE.value;
  const isMainInputURLType = channelConfig?.input?.type === INPUT_URL_TYPE.value;
  const isMainInputWebcamType = channelConfig?.input?.type === INPUT_WEBCAM_TYPE.value;
  const isInputRTMPTypeSelected = channelConfig?.input?.type === INPUT_TYPES.inputRtmp.value;
  const isInputRTPTypeSelected = channelConfig?.input?.type === INPUT_TYPES.inputMpegtsOverRtp.value;
  const isInputNDITypeSelected = channelConfig?.input?.type === INPUT_TYPES.inputNdi.value;

  const selectedInputDesc = decklinkInputs.find((input) => input.device === channelConfig?.input?.device);
  const parsedInputFormat =
    channelConfig?.input?.format === SELECT_DEFAULT_AUTO.value
      ? SELECT_DEFAULT_AUTO.label
      : channelConfig?.input?.format;

  return (
    <Card
      title={
        <Row justify="space-between">
          <FormattedMessage id="InputSection.inputStream" defaultMessage="Input stream" />
          {isInput && !detailView && (
            <Col>
              <StyledButton
                disabled={isMainQSMethod || isMainInputWebcamType}
                icon={<SearchOutlined />}
                onClick={handleShowProbe}
                type="primary"
              >
                <span>
                  <FormattedMessage id="InputSection.probeStream" defaultMessage="Probe stream" />
                </span>
              </StyledButton>
              <Button type="primary" onClick={handleShowEditModal} icon={<EditOutlined />}>
                <span>
                  <FormattedMessage id="general.edit" defaultMessage="Edit" />
                </span>
              </Button>
            </Col>
          )}
        </Row>
      }
      extra={
        detailView &&
        isBackup && <BackupSwitch channelId={id} usesBackup={usesBackup} requestedStatusText={requestedStatusText} />
      }
    >
      <Row gutter={24} justify="center">
        <ProbeDrawer
          channelConfig={channelConfig}
          getFieldValue={getFieldValue}
          isBackup={isBackup}
          isMainQSMethod={isMainQSMethod}
          setShowDrawer={setShowDrawer}
          showDrawer={showDrawer}
          validateFields={validateFields}
        />
        {isInput && (
          <Col span={24}>
            <StyledSpin
              indicator={<></>}
              spinning={usesBackup && detailView}
              tip={<FormattedMessage id="InputSection.inactive" defaultMessage="Inactive" />}
              onClick={() => setInputStreamModalVisible(true)}
            >
              {isMainInputURLType && (
                <InputStreamSourceElement
                  connectionType={channelConfig?.input?.mainConnection?.type}
                  cloudId={channelConfig?.input?.mainConnection.cloudId}
                  destinationAddress={channelConfig?.input?.mainConnection?.destinationAddress}
                  port={
                    channelConfig?.input?.mainConnection?.destinationPort ||
                    channelConfig?.input?.mainConnection?.localPort
                  }
                  handleClick={handleShowEditModal}
                  interfaceIp={channelConfig?.input?.mainConnection?.interfaceIp}
                  label={formatMessage(translations.main)}
                  title={
                    channelConfig?.input?.mainConnection?.type &&
                    parseConnectionMethodName(channelConfig?.input?.mainConnection?.type)
                  }
                  incomplete={channelConfig?.input?.mainConnection?.incomplete}
                />
              )}
              {isMainInputDecklinkType && (
                <DecklinkElement
                  label={INPUT_DECKLINK_TYPE.label}
                  handleClick={handleShowEditModal}
                  selectedInputDesc={selectedInputDesc?.desc}
                  format={parsedInputFormat}
                  incomplete={channelConfig?.input?.incomplete}
                />
              )}
              {isMainInputWebcamType && (
                <WebcamElement
                  bitsPerSample={channelConfig?.input?.bitsPerSample}
                  channels={channelConfig?.input?.channels}
                  handleClick={handleShowEditModal}
                  label={INPUT_WEBCAM_TYPE.label}
                  sampleRate={channelConfig?.input?.sampleRate}
                  audioTitle={channelConfig?.input?.micDevice}
                  videoTitle={channelConfig?.input?.webcamDevice}
                  width={channelConfig?.input?.width}
                  height={channelConfig?.input?.height}
                  fps={channelConfig?.input?.fps}
                  incomplete={channelConfig?.input?.incomplete}
                />
              )}
              {isInputRTMPTypeSelected && (
                <InputStreamSourceElement
                  connectionType={channelConfig?.input?.type}
                  handleClick={handleShowEditModal}
                  label={formatMessage(translations.main)}
                  title={INPUT_TYPES.inputRtmp.label}
                  url={channelConfig?.input?.url}
                  incomplete={channelConfig?.input?.incomplete}
                />
              )}
              {isInputRTPTypeSelected && (
                <InputStreamSourceElement
                  connectionType={channelConfig?.input?.type}
                  handleClick={handleShowEditModal}
                  ip={channelConfig?.input?.ip}
                  label={formatMessage(translations.main)}
                  port={channelConfig?.input?.port}
                  title={INPUT_TYPES.inputMpegtsOverRtp.label}
                  incomplete={channelConfig?.input?.incomplete}
                />
              )}
              {isInputNDITypeSelected && (
                <InputNDIElement
                  label={formatMessage(translations.main)}
                  handleClick={handleShowEditModal}
                  streamSource={channelConfig?.input?.streamSource}
                  title={INPUT_TYPES.inputNdi.label}
                  incomplete={channelConfig?.input?.incomplete}
                />
              )}
            </StyledSpin>
          </Col>
        )}
        {channelConfig?.input?.backupConnection && (
          <Col span={24}>
            <StyledSpin
              indicator={<></>}
              spinning={!usesBackup && detailView}
              tip={<FormattedMessage id="InputSection.inactive" defaultMessage="Inactive" />}
              onClick={() => setInputStreamModalVisible(true)}
            >
              <InputStreamSourceElement
                connectionType={channelConfig?.input?.backupConnection?.type}
                destinationAddress={channelConfig?.input?.backupConnection?.destinationAddress}
                port={
                  channelConfig?.input?.backupConnection?.destinationPort ||
                  channelConfig?.input?.backupConnection?.localPort
                }
                handleClick={() => setInputStreamModalVisible(true)}
                interfaceIp={channelConfig?.input?.backupConnection?.interfaceIp}
                label={formatMessage(translations.backup)}
                title={
                  channelConfig?.input?.backupConnection?.type &&
                  parseConnectionMethodName(channelConfig?.input?.backupConnection?.type)
                }
                incomplete={channelConfig?.input?.backupConnection?.incomplete}
              />
            </StyledSpin>
          </Col>
        )}
        {!isInput && !detailView && (
          <Button type="dashed" style={{ width: "100%" }} onClick={handleShowEditModal}>
            <PlusOutlined /> <FormattedMessage id="InputSection.addInput" defaultMessage="Add input" />
          </Button>
        )}

        {!isInput && detailView && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </Row>
      {inputStreamModalVisible && (
        <StyledModal
          zIndex={100}
          centered
          title={<FormattedMessage id="InputSection.inputStreamForm" defaultMessage="Input Stream Form" />}
          open={inputStreamModalVisible}
          onCancel={handleCancelMainInput}
          footer={
            detailView ? null : (
              <>
                <Button key="back" onClick={handleCancelMainInput}>
                  <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
                </Button>
                {isInitialValueInputUrlType && (
                  <Popconfirm
                    title={
                      <StyledPopconfirmText>
                        <FormattedMessage
                          id="InputSection.beAware"
                          // eslint-disable-next-line max-len
                          defaultMessage="Be aware that this accepting this change you will overwrite the all old input configuration (including input backup), and all direct outputs"
                        />
                      </StyledPopconfirmText>
                    }
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                    onConfirm={handleOKMainInput}
                    okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
                    cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
                  >
                    <Button key="ok" type="primary" icon={<SaveOutlined />}>
                      <span>
                        <FormattedMessage id="general.save" defaultMessage="Save" />
                      </span>
                    </Button>
                  </Popconfirm>
                )}
                {!isInitialValueInputUrlType && (
                  <Button key="ok" type="primary" onClick={handleOKMainInput} icon={<SaveOutlined />}>
                    <span>
                      <FormattedMessage id="general.save" defaultMessage="Save" />
                    </span>
                  </Button>
                )}
              </>
            )
          }
        >
          <InputStreamSection
            isInput={!!channelConfig.input}
            channelList={sharedChannelList}
            disabled={detailView}
            getFieldValue={getFieldValue}
            initialValue={channelConfig?.input}
            setFieldsValue={setFieldsValue}
            setSelectedInputType={setSelectedInputType}
            sharedChannelList={sharedChannelList}
            stundAddress={stundAddress}
            validateFields={validateFields}
            form={form}
          />
        </StyledModal>
      )}
    </Card>
  );
};

const StyledButton = styled(Button)`
  margin: 0 5px;
`;

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledSpin = styled(Spin)`
  .ant-spin-text {
    top: 30% !important;
    font-weight: bold;
  }
`;

const StyledPopconfirmText = styled.div`
  max-width: 350px;
`;

InputSection.propTypes = {
  channelConfig: PropTypes.object,
  detailView: PropTypes.bool,
  decklinkInputs: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string,
      device: PropTypes.string,
    })
  ).isRequired,
  getFieldValue: PropTypes.func.isRequired,
  handleFinish: PropTypes.func.isRequired,
  id: PropTypes.string,
  requestedStatusText: PropTypes.string,
  resetFields: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  sharedChannelList: PropTypes.array,
  stundAddress: PropTypes.string,
  usesBackup: PropTypes.bool,
  validateFields: PropTypes.func.isRequired,
};

InputSection.defaultProps = {
  channelConfig: null,
  detailView: null,
  id: null,
  requestedStatusText: null,
  sharedChannelList: null,
  stundAddress: null,
  usesBackup: null,
};

const mapStateToProps = (state) => ({
  decklinkInputs: NODE_DEVICES_SELECTORS.getDecklinkInputs(state),
});

export default connect(mapStateToProps, null)(InputSection);
