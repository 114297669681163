import React, { useCallback } from "react";
import { Card, Form, Row, Button, Col, Input, Tooltip, Spin, Skeleton } from "antd";
import semver from "semver";
import { useSelector } from "react-redux";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { QuestionCircleFilled, LockOutlined } from "@ant-design/icons";

import { selectors as NODE_SELECTORS } from "../../../../ducks/node";
import MQTTService from "../../../../services/mqtt";

import { required, password } from "../../../../lib/utils/formValidation";
import { MESSAGE_TYPE } from "../../../../lib/utils/constants";

const { Item } = Form;
const { Password } = Input;

const translations = defineMessages({
  confirmPasswordNotIdentical: {
    id: "LocalPasswordForm.validationNotIdentical",
    defaultMessage: "Confirmation password need to be the same as password",
  },
});

export default function LocalPasswordForm() {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { resetFields, getFieldValue } = form;
  const nodeVersion = useSelector(NODE_SELECTORS.getNodeVersion);
  const cwid = useSelector(NODE_SELECTORS.getNodeCwid);
  const hideForOldNodeVersion = nodeVersion && semver.lt(nodeVersion, "4.0.8");

  const validatePassword = useCallback(
    (rule, value) => {
      const passwordValue = getFieldValue("newPass");
      if (value !== passwordValue) {
        return Promise.reject(formatMessage(translations.confirmPasswordNotIdentical));
      }

      return Promise.resolve();
    },
    [formatMessage, getFieldValue]
  );

  if (hideForOldNodeVersion) {
    return null;
  }

  if (nodeVersion === "") {
    return (
      <Spin tip={<FormattedMessage id="LocalPasswordForm.loadingNodeData" defaultMessage="Loading node data" />}>
        <Skeleton active />
      </Spin>
    );
  }

  const handleFinish = (data) => {
    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.SETUP_LOCAL_USER,
        ...data,
      },
    });
    resetFields();
  };

  return (
    <StyledCard
      title={
        <FormattedMessage id="LocalPasswordForm.editNodeLocalPassword" defaultMessage="Edit Node local password" />
      }
    >
      <Form onFinish={handleFinish} form={form} initialValues={{ oldPass: "" }}>
        <Row gutter={24}>
          <Col span={24}>
            <Item
              name="oldPass"
              label={
                <>
                  <FormattedMessage
                    id="LocalPasswordForm.oldLocalPassword"
                    defaultMessage="Old local password (node)"
                  />
                  <Tooltip
                    placement="top"
                    title={
                      <span>
                        <FormattedMessage
                          id="LocalPasswordForm.info"
                          // eslint-disable-next-line max-len
                          defaultMessage="Please enter node old password or leave blank if setting password for the first time"
                        />
                      </span>
                    }
                  >
                    <StyledIcon>
                      <QuestionCircleFilled />
                    </StyledIcon>
                  </Tooltip>
                </>
              }
              rules={[password]}
            >
              <Password type="password" autoComplete="off" prefix={<LockOutlined />} />
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Item
              name="newPass"
              label={
                <FormattedMessage id="LocalPasswordForm.newLocalPassword" defaultMessage="New local password (node)" />
              }
              rules={[{ validator: validatePassword }, password, required]}
            >
              <Password type="password" autoComplete="off" prefix={<LockOutlined />} />
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Item
              name="confirmPassword"
              label={
                <FormattedMessage id="LocalPasswordForm.confirmPassword" defaultMessage="Confirm new local password" />
              }
              rules={[{ validator: validatePassword }, password, required]}
            >
              <Password type="password" autoComplete="off" prefix={<LockOutlined />} />
            </Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="global.save" defaultMessage="Save" />
        </Button>
      </Form>
    </StyledCard>
  );
}

const StyledIcon = styled.span`
  margin-left: 5px;
`;

const StyledCard = styled(Card)`
  margin-top: 15px;
`;
