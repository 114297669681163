import * as React from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";

import { CONNECTION_METHOD_INPUT } from "../../../lib/utils/constants";

import { PortField } from "../../Fields/Fields";
import InputNumberField from "../../Fields/InputNumberField";
import NetworkField from "../../Fields/NetworkField";
import PassphraseField from "../../Fields/PassphraseField";

import { required } from "../../../lib/utils/formValidation";

import OverheadBandwidthLabel from "../Labels/OverheadBandwidthLabel";
import HostField from "../../HostField";
import LatencyLabel from "../Labels/LatencyLabel";
import LocalPortLabel from "../../LocalPortLabel";
import StreamIdFields from "../../StreamIdFields";
import AdvancedSettings from "../../AdvancedSettings";

const InputStreamSrtSection = ({ prefix, disabled, connectionMethod, getFieldValue, setFieldsValue, form }) => {
  const isCallerMode = connectionMethod === CONNECTION_METHOD_INPUT.inSrtCaller.value;
  const isListenerMode = connectionMethod === CONNECTION_METHOD_INPUT.inSrtListener.value;

  return (
    <>
      {isCallerMode && (
        <>
          <Col md={24} lg={12}>
            <HostField disabled={disabled} name={[...prefix, "destinationAddress"]} />
          </Col>
          <Col md={24} lg={12}>
            <PortField name={[...prefix, "destinationPort"]} disabled={disabled} />
          </Col>
        </>
      )}
      <Col md={24} lg={12}>
        <NetworkField name={[...prefix, "interfaceIp"]} requiredField disabled={disabled} />
      </Col>
      <Col md={24} lg={12}>
        <PortField
          name={[...prefix, "localPort"]}
          disabled={disabled}
          label={<LocalPortLabel noTooltip={isListenerMode} />}
          min={isListenerMode ? 1 : 0}
        />
      </Col>
      <Col md={24} lg={12}>
        <InputNumberField
          disabled={disabled}
          name={[...prefix, "srtOverhead"]}
          label={OverheadBandwidthLabel()}
          rules={[required]}
          min={5}
          max={100}
        />
      </Col>
      <Col md={24} lg={12}>
        <InputNumberField
          disabled={disabled}
          name={[...prefix, "srtLatency"]}
          label={LatencyLabel("input")}
          rules={[required]}
        />
      </Col>
      <AdvancedSettings>
        <Row gutter={24}>
          <StreamIdFields prefix={prefix} form={form} disabled={disabled} />
          <Col md={24} lg={24}>
            <PassphraseField
              disabled={disabled}
              getFieldValue={getFieldValue}
              prefix={[...prefix]}
              setFieldsValue={setFieldsValue}
              type={prefix[0]}
            />
          </Col>
        </Row>
      </AdvancedSettings>
    </>
  );
};

InputStreamSrtSection.propTypes = {
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  connectionMethod: PropTypes.string.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
};

InputStreamSrtSection.defaultProps = {
  disabled: null,
};

export default InputStreamSrtSection;
